import React, { Fragment, FunctionComponent, useEffect, useState} from 'react'
import { Outlet, Route, Routes} from 'react-router-dom'
import ProjectsPage from './features/projects/ProjectsPage'
import LoginPage from './features/user/LoginPage'
import UsersListPage from './features/administration/pages/UserListPage'
import RolesListPage from './features/administration/pages/RolesListPage'
import RolePage from './features/administration/pages/RolePage'
import UserPage from './features/administration/pages/UserPage'
import RightsListPage from './features/administration/pages/RightsListPage'
import userManager, { loadUser, signinRedirect } from './auth/user-service'
import AuthProvider from './auth/AuthProvider'
import SignoutOidc from './auth/SignoutOidc'
import SigninOidc from './auth/SinginOidc'
import { User } from 'oidc-client-ts'
import { Box, Stack, Typography } from '@mui/material'
import SilentRenewOidc from './auth/SilentRenewOidc'

const App: FunctionComponent = () => {
  return (
    <Fragment>
      <AuthProvider manager={userManager}>
        <Routes>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/signout-oidc" element={<SignoutOidc/>}/>
          <Route path="/signin-oidc" element={<SigninOidc/>}/>
          <Route path="/renew" element={<SilentRenewOidc/>}/>
          <Route element={<PrivateRoute/>}>
            <Route path="/user" element={<UserPage/>}/>
            <Route path="/administration/users" element={<UsersListPage/>}/>
            <Route path="/administration/roles" element={<RolesListPage/>}/>
            <Route path="/administration/roles/:roleId" element={<RolePage/>}/>
            <Route path="/administration/rights" element={<RightsListPage/>}/>
            <Route path="/" element={<ProjectsPage/>}/>
            <Route path="*" element={<p>Error</p>}/>
          </Route>
        </Routes>
      </AuthProvider>
    </Fragment>
  )
}

const PrivateRoute: FunctionComponent =  () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  useEffect(() => {
    loadUser()
      .then((user) => {
        if(user){
          setCurrentUser (user)
        }
        else {
          signinRedirect()
        }
      }).catch(() => signinRedirect());
  }, [])

  return currentUser ? <Outlet/> :  <Box
    display="flex"
    minHeight="50vh"

    textAlign="center"
    justifyContent="center"
    alignItems="center"
  >
    <Stack
      direction={'column'}
      spacing={2}
      maxWidth="75%"
      justifyContent="center"
      alignItems="center">
      <Typography style={{ wordBreak: 'break-all' }}>
        Checking the authentication requirement, wait please
      </Typography>
    </Stack>
  </Box>
}



export default App
